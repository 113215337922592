<template>
  <CContainer>
    <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <h3>Noticias</h3>
          </CCardHeader>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardHeader>
            <CRow class="">
              <CCol col="3">
                <router-link :to="'/crear/noticia'">
                  <CButton size="sm" color="success"> Crear Noticia </CButton>
                </router-link>
              </CCol>
              <CCol col="6">
                <h5 class="text-center">
                  Cantidad de noticias creadas: {{ items.length }}
                </h5>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              hover
              striped
              :items="items"
              :fields="fields"
              :items-per-page="10"
              :pagination="{ doubleArrows: false, align: 'center' }"
              column-filter
              @page-change="pageChange"
            >
              <template #autoincriment="{ item, index }">
                <td>
                  {{ index + 1 }}
                </td>
              </template>
              <template #acciones="{ item }">
                <td>
                  <CButton
                    size="sm"
                    color="success"
                    @click="abrirmodalVerNoticia(item.items)"
                  >
                    Ver
                  </CButton>
                </td>
                <td>
                  <router-link :to="'/noticia/editar/' + item.id">
                    <CButton size="sm" color="info"> Editar </CButton>
                  </router-link>
                </td>
                <td>
                  <CButton
                    size="sm"
                    color="danger"
                    @click="eliminarNoticia(item.id, item.titulo)"
                  >
                    Eliminar
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <div>
      <CToaster :autohide="5000" class="d-inline-block">
        <template v-for="toast in mostrarNotificacion">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Notificacion Exitosa"
          >
            {{ mensaje_success }}
          </CToast>
        </template>
        <template v-for="toast1 in mostrarNotificacionAlerta">
          <CToast
            :key="'toast1' + toast1"
            :show="true"
            color="warning"
            header="Notificacion Alerta"
          >
            {{ mensaje_warning }}
          </CToast>
        </template>
        <template v-for="toast2 in mostrarNotificacionError">
          <CToast
            :key="'toast2' + toast2"
            :show="true"
            color="danger"
            header="Notificacion Alerta"
          >
            Error Comunicarse Con Soporte
          </CToast>
        </template>
      </CToaster>
      <VerNoticia />
    </div>
  </CContainer>
</template>


<script>
import { CCarousel, CCarouselItem } from "@coreui/vue";
//import VerNoticiaUsuarios from "./VerNoticiaUsuarios.vue";
import VerNoticia from "./VerNoticia.vue";
export default {
  name: "Noticias",
  components: { CCarousel, CCarouselItem, VerNoticia },
  data() {
    return {
      items: [],
      fields: [
        { key: "autoincriment", label: "#", _style: { width: "1%" } },
        { key: "titulo", label: "Titulo", _classes: "font-weight-bold" },
        { key: "fecha_inicio", label: "Fecha Inicio" },
        { key: "fecha_fin", label: "Fecha Fin" },
        {
          key: "acciones",
          label: "",
          _style: { width: "1%" },
          sorter: false,
          filter: false,
        },
      ],
      contador: 0,
      activePage: 1,
      collapseDuration: 0,
      nombre_grupo: "",
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      mensaje_success: "Noticia Creada Correctamente.",
      modal_eliminar_grupo: false,
      form_eliminar_grupo: {
        nombre: "",
        id: "",
      },
      labelsChart: [],
      dataChart: [],
      verItemsNoticias: [],
      tituloNoticia: "",
      showModal: true,
      modal_carrousel: "hidden",
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case 1:
          return "success";
        case 0:
          return "danger";
      }
    },
    rowClicked(item, index) {
      this.$router.push({ path: `users/${index + 1}` });
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    toggleDetails(item) {
      this.$set(this.items[item.id], "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    getNoticias() {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .get("/sms/noticias")
        .then((response) => {
          this.items = response.data;
          this.items.map((item, id) => {
            if (id % 2 == 0) {
              item["_classes"] = "table-now";
            }
            return item;
          });
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    eliminarNoticia(id_noticia, titulo) {
      // Use sweetalert2
      this.$swal
        .fire({
          title: `Desea Eliminar La Noticia : ${titulo} ?`,
          showCancelButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.eliminarNoticiaRequest(id_noticia);
          }
        });
    },
    eliminarNoticiaRequest(id_noticia) {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/noticias/eliminar", {
          id_noticia: id_noticia,
        })
        .then((response) => {
          if (response.data == "OK") {
            this.mensaje_success = "Noticia Eliminada Correctamente";
            this.mostrarNotificacion++;
            this.getNoticias();
          }
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    abrirmodalVerNoticia(items) {
      this.$root.$emit("abrirModalVerNoticia", "visible", items);
    },
  },
  mounted() {
    setTimeout(() => {
      this.getNoticias();
    }, 100);
  },
  computed: {},
};
</script>
